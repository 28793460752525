export function initAlpineCart() {
    return {
        endpoints: {
            cart: '/checkout_api/carts/',
            checkout: '/checkout_api/customercheckout/',
        },
        wishlist: wishlistOnLoad,
        items: [
            /* {
                // Product Info
                unique_key: str -> <sku>-<siteid> OR chosen id.
                site_id: 1, (will always be 1, we don't use sites framework),
                sku: str,
                name: product brand + title,
                unit: str -> package size, ea, or cart unit,
                image: relative static url to the image,
                // Item info
                quantity: int -> order items can be decimal, but we should be using cart units prior to ordering,
                can_substitute: bool,
                note: str,
                // Pricing Info
                price: decimal -> unit price,
                price_regular: decimal -> non-sale price (will be equal to price if not on sale)
                display_price: str -> formatted display price with cur symbol etc...,
                display_price_regular: str -> formatted non-sale price,
                on_sale: bool -> note: a product can be on sale even if price == regular price (eg, bogo sale)
            }*/
        ],
        subTotal: null,
        totalQuantity: null,
        itemInCart(unique_key) {
            return this.items.find((item) => item.unique_key === unique_key)
        },
        async updateItem(data) {
            await this.sendCart(data, 'update_item')
        },
        async removeItem(data) {
            await this.sendCart(data, 'remove_item')
        },
        async getCart() {
            await this.sendCart({}, 'get_cart', 'GET')
        },
        async saveCart() {
            await this.sendCart({}, 'save_cart')
        },
        async saveShoppingList(list_name) {
            await this.sendCart({ name: list_name }, 'save_shoppinglist')
        },
        async updateItemsFromList(data) {
            await this.sendCart(data, 'update_items_from_list')
        },
        async emptyCart() {
            await this.sendCart({}, 'empty_cart')
        },
        async sendCart(body, action, method='POST') {
            let endpoint = this.endpoints.cart + action + '/'
            const params = {
                method: method,
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': window.csrf_token,
                }
            }
            if (method === 'POST'){
                params['body'] = JSON.stringify(body)
            }
            const response = await fetch(endpoint, params)

            const data = await response.json()
            this.items = data.cart.items
            this.totalQuantity = data.cart.total_quantity
            this.subTotal = data.cart.sub_total

            if (this.onCheckout) {
                console.debug(
                    "Looks like we're on the checkout page. Reloading checkout data"
                )
                this.getCheckout()
            }
            return data
        },
        floatFormat(value, decimalPlaces = 2) {
            // This is roughly equivalent to django's |floatformat
            if (isNaN(value)) {
                return value // Return the original value if it's not a number
            }

            // Check if the number has no fractional part
            if (value % 1 === 0) {
                return parseInt(value, 10) // Return as an integer
            }

            const multiplier = 10 ** decimalPlaces
            const roundedValue = Math.round(value * multiplier) / multiplier

            // Convert the rounded value to a string with the specified decimal places
            return roundedValue.toFixed(decimalPlaces)
        },
    }
}
